.tuby {
  &-seek {
    flex-shrink: 0;
    height: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin: 0 12px;
    position: relative;

    &:hover &-bar {
      height: 5px;
    }

    &-bar {
      height: 3px;
      background: #ffffff80;
      transition: 0.15s;
    }

    &-left {
      height: 100%;
      background: var(--tuby-primary-color);
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 12px;
        width: 12px;
        right: -6px;
        top: 50%;
        transform: translateY(-50%) scale(0);
        border-radius: 99999px;
        background: var(--tuby-primary-color);
        transition: 0.15s;
      }
    }
    &:hover &-left::after {
      transform: translateY(-50%) scale(1);
    }

    &-preview {
      position: absolute;
      top: -24px;
      font-size: 12px;
      background: #000000b6;
      color: #fff;
      padding: 3px 7px;
      pointer-events: none;
      animation: tuby-fade-forward 0.2s forwards;
    }
  }
}
@keyframes tuby-fade-forward {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
