.tuby {
  position: relative;
  color: #fff;
  background: #000;

  &-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    pointer-events: none;
    z-index: 1;
  }

  video {
    width: 100%;
    height: 100%;
  }

  &-time {
    font-size: 14px;
  }
}
