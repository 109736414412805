@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-main;
    box-sizing: border-box;
    overflow-y: auto;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    min-height: auto;
    min-width: 320px !important;
    /* max-width: 1600px !important; */
    width: 100vw !important;
    color: white;
  }

  a {
    color: inherit;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333333;
  }

  .liked {
    animation: likeAnimation 0.3s linear;
  }

  .disliked {
    animation: dislikeAnimation 0.3s linear;
  }

  @keyframes likeAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes dislikeAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }
  .upload-image,
  .upload-video {
    min-width: 0;
    min-height: 0;
    width: 100%;
    height: 100%;
  }
}
input:focus,
textarea:focus,
select:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

/* Basic class for tailwind */
.linear-bg {
  background: linear-gradient(269.82deg, #07091c00 0.17%, #080a1ad6 99.87%);
}

.hoveres {
  @apply hidden;
}

.hovered:hover .hoveres {
  @apply flex-colo;
}

.flex-colo {
  @apply flex flex-col justify-center items-center;
}

.flex-rows {
  @apply flex flex-row justify-center items-center;
}

.flex-btn {
  @apply flex justify-between items-center;
}

.transitions {
  @apply transition duration-300 ease-in;
}

.zIndex {
  z-index: 1000;
}

/* Drawer */

.drawer {
  overflow: visible;
}

.drawer .drawer-content-wrapper {
  width: 100%;
}

.drawer {
  position: fixed;
  z-index: 9999;
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.drawer > \* {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.drawer.drawer-open {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.drawer .drawer-mask {
  background: #000;
  opacity: 0;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    height 0s ease 0.3s;
}

.drawer-content-wrapper {
  position: absolute;
  background: #fff;
}

.drawer-content {
  overflow: auto;
  z-index: 1;
  position: relative;
}

.drawer-handle {
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: none;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.drawer-handle-icon {
  width: 14px;
  height: 2px;
  background: #333;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.drawer-handle-icon:before,
.drawer-handle-icon:after {
  content: "";
  display: block;
  position: absolute;
  background: #333;
  width: 100%;
  height: 2px;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.drawer-handle-icon:before {
  top: -5px;
}

.drawer-handle-icon:after {
  top: 5px;
}

.drawer-left,
.drawer-right {
  width: 0%;
  height: 100%;
}

.drawer-left .drawer-content-wrapper,
.drawer-right .drawer-content-wrapper,
.drawer-left .drawer-content,
.drawer-right .drawer-content {
  height: 100%;
}

.drawer-left.drawer-open,
.drawer-right.drawer-open {
  width: 100%;
}

.drawer-left.drawer-open.no-mask,
.drawer-right.drawer-open.no-mask {
  width: 0%;
}

.drawer-left {
  top: 0;
  left: 0;
}

.drawer-left .drawer-handle {
  right: -40px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}

.drawer-left.drawer-open .drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}

.drawer-right {
  top: 0;
  right: 0;
}

.drawer-right .drawer-content-wrapper {
  right: 0;
}

.drawer-right .drawer-handle {
  left: -40px;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
}

.drawer-right.drawer-open .drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}

.drawer-right.drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}

.drawer-top,
.drawer-bottom {
  width: 100%;
  height: 0%;
}

.drawer-top .drawer-content-wrapper,
.drawer-bottom .drawer-content-wrapper,
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  width: 100%;
}

.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  height: 100%;
}

.drawer-top.drawer-open,
.drawer-bottom.drawer-open {
  height: 100%;
}

.drawer-top.drawer-open.no-mask,
.drawer-bottom.drawer-open.no-mask {
  height: 0%;
}

.drawer-top .drawer-handle,
.drawer-bottom .drawer-handle {
  left: 50%;
  margin-left: -20px;
}

.drawer-top {
  top: 0;
  left: 0;
}

.drawer-top .drawer-handle {
  top: auto;
  bottom: -40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}

.drawer-top.drawer-open .drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.drawer-bottom {
  bottom: 0;
  left: 0;
}

.drawer-bottom .drawer-content-wrapper {
  bottom: 0;
}

.drawer-bottom .drawer-handle {
  top: -40px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
}

.drawer-bottom.drawer-open .drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}

.drawer-bottom.drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}

.drawer.drawer-open .drawer-mask {
  opacity: 0.3;
  height: 100%;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.drawer.drawer-open .drawer-handle-icon {
  background: transparent;
}

.drawer.drawer-open .drawer-handle-icon:before {
  transform: translateY(5px) rotate(45deg);
}

.drawer.drawer-open .drawer-handle-icon:after {
  transform: translateY(-5px) rotate(-45deg);
}

@media (min-width: 480px) {
  .drawer .drawer-content-wrapper {
    width: 420px;
  }
}

.post-header {
  max-width: 600px !important;
}

/* SOCIAL LOGIN */
.social-login {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  width: 100%;
}
.social-login.hide {
  visibility: hidden;
}

.relogin > button {
  margin-top: 10px;
}
.social-login::before {
  @apply bg-main;
  content: "";
  display: block;
  position: absolute;
  -webkit-transform: translate3d(0, 0, -1);
  transform: translate3d(0, 0, -1);
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  top: 2px;
  left: 2px;
  z-index: -1;
}
#autoflow {

  /* Set scrollbar width */
  scrollbar-width: thin; /* or auto */

  /* For WebKit (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 7px; /* Set scrollbar width */
  }
  &::-webkit-scrollbar-thumb {
    @apply bg-main;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    @apply bg-boxes; /* Set thumb color */
  }

  &::-webkit-scrollbar-track {
    @apply bg-boxes; /* Set track color */
  }
}
.som {
  color: #F20000;
  color: #38fc38; 
}
.social-login::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -3;
  opacity: 0.7;
  background: linear-gradient(
    269.16deg,
    #ffe580 -15.83%,
    #ff7571 -4.97%,
    #ff7270 15.69%,
    #ea5dad 32.43%,
    #c2a0fd 50.09%,
    #9867f0 67.47%,
    #3bf0e4 84.13%,
    #33ce43 105.13%,
    #b2f4b6 123.24%
  );
  transition: opacity 0.3s ease-in-out;
}

.social-login:hover:after {
  opacity: 1;
}

.storybook-button {
  font-weight: 700;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 4px 12px;
  font-size: 13px;
}
.storybook-button--primary {
  color: white;
  background-color: #1ea7fd;
}

.pinterest-btn {
  display: block;
  border: 0px;
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 50%) 0px 1px 2px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-size: 19px;
  margin: 5px;
  overflow: hidden;
  padding: 0px 10px;
  user-select: none;
  height: 50px;
  background: rgb(255, 255, 255);
}

.pinterest-btn .content {
  align-items: center;
  display: flex;
  height: 100%;
}

.pinterest-btn .txt {
  margin-left: 10px;
}

.pinterest-btn .icon {
  display: flex;
  justify-content: center;
  min-width: 26px;
}

.social-login-card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  color: #eceff1;
  border-radius: 5px;
  display: "flex";
  flex-direction: "column";
  width: 100%;
  max-width: 450px;
  height: 450px !important;
  align-items: center;
  justify-content: center;
  padding: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.social-login-card::before {
  content: "";
  display: block;
  position: absolute;
  -webkit-transform: translate3d(0, 0, -1);
  transform: translate3d(0, 0, -1);
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  top: 2px;
  left: 2px;
  background: #000;
  z-index: -1;
}

.social-login-card::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -3;
  opacity: 0.7;
  background: linear-gradient(
    269.16deg,
    #ffe580 -15.83%,
    #ff7571 -4.97%,
    #ff7270 15.69%,
    #ea5dad 32.43%,
    #c2a0fd 50.09%,
    #9867f0 67.47%,
    #3bf0e4 84.13%,
    #33ce43 105.13%,
    #b2f4b6 123.24%
  );
  transition: opacity 0.3s ease-in-out;
}

.social-login-card:hover:after {
  opacity: 1;
}

.provider {
  margin: 2rem 0;
  background: linear-gradient(
    270.97deg,
    #ffe580 -21.36%,
    #ff7571 -2.45%,
    #ea5dad 26.84%,
    #c2a0fd 64.15%,
    #3bf0e4 108.29%,
    #b2f4b6 159.03%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 1.5rem;
}

.avt {
  background: #eceff1;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
  border: 5px solid white;
  border-radius: 50%;
  height: 8em;
  width: 8em;
  margin: auto;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: -5.5rem;
  margin-bottom: 20px;
  position: relative;
}
.avt img {
  height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.data {
  margin: 12px 0;
  width: 100%;
  max-height: 50vh;
  overflow: auto;
}

.btnLogout {
  font-size: 1em;
  text-transform: uppercase;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  background: rgb(241, 69, 61);
  cursor: pointer;
  border-radius: 8px;
  padding: 0.75rem 0px;
  color: rgb(255, 255, 255);
  width: 100%;
  text-align: center;
}

.field {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.label {
  width: 180px;
  font-weight: 400;
  margin-right: 24px;
  font-weight: bold;
  word-break: break-word;
}

.value {
  width: 100%;
  word-break: break-word;
  font-size: 1.25em;
}
.disabled-button {
  background-color: #ccc; /* Set the desired grey color */
  cursor: not-allowed;
}

.dice-container {
  width: 40px;
  height: 40px;
  position: relative;
  animation: diceRotation 0.2s infinite linear;
}

.dice-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  animation: circleMovement 2s infinite linear;
}

.dice-circle-0 { top: 0; left: 15px; }
.dice-circle-1 { top: 0; left: 25px; }
.dice-circle-2 { top: 0; left: 35px; }
.dice-circle-3 { top: 15px; left: 0; }
.dice-circle-4 { top: 25px; left: 0; }
.dice-circle-5 { top: 35px; left: 0; }

@keyframes diceRotation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes circleMovement {
  0% { transform: translateY(0); }
  25% { transform: translateY(-15px); }
  50% { transform: translateY(-15px); }
  75% { transform: translateY(0); }
  100% { transform: translateY(0); }
}

.skeleton {
  opacity: .7;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  margin-bottom: .25rem;
  border-radius: .125rem;
}


@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

/* styles.css */
@keyframes stripes {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.gradient-bg {
  background: linear-gradient(90deg, #FF4500, #ffffff);
  background-size: 200% 100%;
  animation: stripes 2s linear infinite;
}
/* .comments-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.comments-container.show {
  max-height: 500px;
} */
.comments-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%; /* Adjust the desired height */
  background-color: white; /* Set the background color */
  z-index: 999; /* Adjust the z-index to layer it above other content */
  overflow-y: auto; /* Add a scrollbar if needed */
  transition: transform 0.3s ease-in-out; /* Add a transition for smooth animation */
  transform: translateY(100%); /* Start with the comments hidden off-screen */
}

.comments-container.show {
  transform: translateY(0); /* Slide up to show the comments */
}

